import React, { useEffect, useState, useContext  } from "react"

import {
  Col,
  Row,
  Card,
  CardBody,
  // CardTitle,
  // CardSubtitle,
  // CardImg,
  // CardText,
  // CardHeader,
  // CardImgOverlay,
  // CardFooter,
  // CardDeck,
  Container,
  Label,
  Input,
  InputGroup,
  Form,
  FormFeedback,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Spinner,
} from "reactstrap"

import Select from "react-select"
import CreatableSelect from "react-select/creatable"

import { Editor } from "@tinymce/tinymce-react"

import classnames from "classnames"

import { checkScope } from "helpers/utility_helper"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { apiError } from "../../store/actions"
import {
  createQuestionaireCategory,
  createQuestionaireCategoryReset,
} from "../../store/actions"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb2"
import { withTranslation } from "react-i18next"
import "../../components/Common/ts-css.css"
import { Link, useNavigate, useParams } from "react-router-dom"
import _ from "lodash"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import fn_helper from "helpers/fn_helper"
import { CheckBannerPage, CreateBannerPage, GetBannerPageById, SearchBannerPage, UpdateBannerPage } from "services/api/module/BannerPage"
import ModalContext from "../../contexts/ModalContext";
import { DropdownBannerPlatform } from "services/api/module/Dropdown"

const STATUS_OPTION = [
  { id: "0", name: "Inactive" },
  { id: "1", name: "Active" },
]

const BannerPageTemplate = ({
  pageView = "create"
}) => {
  // POPUP
  const { mAlert } = useContext(ModalContext);

  // OTHER STATE
  const qParams = useParams()
  const qBannerPageId = qParams.id
  const navigate = useNavigate();

  // ========== Form Validation ========== //
  const validationSchema = Yup.object({
    bannerPlatformId: Yup.string().max(100).required("Please Select Banner Platform"),
    pageName: Yup.string().max(100).required("Please Enter Page Name"),
    description: Yup.string().max(500),
    bannerWidth: Yup.string().max(100).required("Please Enter Banner Width"),
    bannerHeight: Yup.string().max(100).required("Please Enter Banner Height"),
    bannerMobileWidth: Yup.string().max(100).required("Please Enter Banner Mobile Width"),
    bannerMobileHeight: Yup.string().max(100).required("Please Enter Banner Mobile Height"),
    status: Yup.string().max(100).required("Please Select Status"),
  })

  // LOCAL STATE FORM
  const [updatedValidationSchema, setUpdatedValidationSchema] = useState(validationSchema)
  const [pageNameError, setPageNameError] = useState(false)

  // LOCAL STATE DROPDOWN
  const [ddBannerPlatform, setDDBannerPlatform] = useState([])

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: "",
      bannerPlatformId: "",
      pageName: "",
      description: "",
      bannerWidth: "",
      bannerHeight: "",
      bannerMobileWidth: "",
      bannerMobileHeight: "",
      status: ""
    },
    validationSchema: updatedValidationSchema,
    onSubmit: values => {
      let ERR_FORM = [ pageNameError ]

      if (ERR_FORM.includes(true)) {
        return console.log('Error FROM')
      }

      console.log("🚀 ~ BannerPageCreate ~ values:", values)
      const payload = {
        id: values.id ? values.id : undefined,
        bannerPlatformId: Number(values.bannerPlatformId),
        pageName: values.pageName,
        description: values.description,
        bannerWidth: Number(values.bannerWidth),
        bannerHeight: Number(values.bannerHeight),
        bannerMobileWidth: Number(values.bannerMobileWidth),
        bannerMobileHeight: Number(values.bannerMobileHeight),
        status: Number(values.status)
      };
      console.log("🚀 ~ onSave ~ payload:", payload);

      if (pageView === "update") {
        API.fetchUpdateBannerPage(payload)
      } else {
        API.fetchCreateBannerPage(payload)
      }
    },
  })

  const preFilInput = (resData = {}) => {
    // console.log("🚀 ~ preFilInput ~ resData:", resData)
    validation.setFieldValue("id", resData.id)
    validation.setFieldValue("bannerPlatformId", resData.bannerPlatformId)
    validation.setFieldValue("pageName", resData.pageName)
    validation.setFieldValue("description", resData.description)
    validation.setFieldValue("bannerWidth", resData.bannerWidth)
    validation.setFieldValue("bannerHeight", resData.bannerHeight)
    validation.setFieldValue("bannerMobileWidth", resData.bannerMobileWidth)
    validation.setFieldValue("bannerMobileHeight", resData.bannerMobileHeight)
    validation.setFieldValue("status", resData.status)
  }

  const API = {
    fetchDDBannerPlatform: async () => {
      try {
          const response = await DropdownBannerPlatform({})
          const resData = response?.data ?? []
          setDDBannerPlatform(resData.data);
      } catch (e) {
          console.log(e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchCheckBannerPage: async (payload) => {
      try {
          const response = await CheckBannerPage({ data: payload })
          const resData = response.data
          if (resData.status == 202) {
            let tmpResData = resData.data.filter(item => item.id != qBannerPageId)
            if (tmpResData.length > 0) {
              setPageNameError(true)
            } else {
              setPageNameError(false)
            }
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchGetBannerPageById: async (payload) => {
      try {
          const response = await GetBannerPageById({ id: payload.id })
          const resData = response.data
          if (resData.status == 202) {
            preFilInput(resData.data)
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
            title: "Alert",
            subTitle: "Something went wrong",
            content: "",
            onClose: () => {
            //  alert("onClose")
            },
        })
      }
    },
    fetchCreateBannerPage: async (payload) => {
      try {
          const response = await CreateBannerPage({ data: payload })
          console.log("🚀 ~ fetchCreateBannerPage: ~ response:", response)
          const resData = response.data
          if (resData.status === 201) {
            mAlert.info({
                title: "Alert",
                subTitle: "Created Data Success",
                content: "",
                onClose: () => {
                  navigate('/banner/banner-page')
                },
            })
          }
          else if (resData.status === 403) {
            mAlert.info({
                title: "Alert",
                subTitle: "Group page name already exists",
                content: "",
                onClose: () => {
                },
            })
          }
      } catch (e) {
          console.log('e ', e)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    },
    fetchUpdateBannerPage: async (payload) => {
      try {
          const response = await UpdateBannerPage({ data: payload, id: payload.id })
          const resData = response.data
          if (resData.status === 201) {
              mAlert.info({
                  title: "Alert",
                  subTitle: "Update Data Success",
                  content: "",
                  onClose: () => {
                    navigate('/banner/banner-page')
                  },
              })
          }
          else if (resData.status === 403) {
            mAlert.info({
                title: "Alert",
                subTitle: "Group page name already exists",
                content: "",
                onClose: () => {
                },
            })
          }
      }
      catch (err) {
          console.log('err ', err)
          mAlert.info({
              title: "Alert",
              subTitle: "Something went wrong",
              content: "",
              onClose: () => {
              //  alert("onClose")
              },
          })
      }
    }
  }

  // กรณี Edit
  useEffect(() => {
    let timeoutInput = null
    if (validation.values.bannerPlatformId) {
      timeoutInput = setTimeout(() => {
        const payload = { pageName: validation.values.pageName, bannerPlatformId: validation.values.bannerPlatformId}
        console.log("🚀 ~ timeoutInput ~ payload:", payload)
        API.fetchCheckBannerPage(payload)
      }, 200)
    }


    return () => clearTimeout(timeoutInput)
    
  }, [validation.values.pageName, validation.values.bannerPlatformId])

  // กรณี Edit
  useEffect(() => {

    // CALL DROPDOWN
    API.fetchDDBannerPlatform();

    if (["update", "view"].includes(pageView)) {
      const payload = { id : qBannerPageId }
      API.fetchGetBannerPageById(payload)
    }
  }, [])

  const PAGE_VIEW = _.capitalize(_.first(pageView) + pageView.slice(1))
  document.title = `Banner Page | ${PAGE_VIEW} | ` + process.env.REACT_APP_CMS_TITLE

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={`${PAGE_VIEW} Banner Page`}
            breadcrumbItems={[
              { title: "Banner Page", link: "banner/banner-page" },
              { title: PAGE_VIEW },
            ]}
          />
          <Row>
            <Col>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <h5 className="mb-3">Banner Page Infomation</h5>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner Platform
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Banner Platform"
                            value={fn_helper.FNFORM.getObjectValue(ddBannerPlatform, `${validation.values.bannerPlatformId}`)}
                            onChange={(value) => validation.setFieldValue("bannerPlatformId", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={ddBannerPlatform}
                            className={`select2-selection ${validation.touched.bannerPlatformId && validation.errors.bannerPlatformId ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.bannerPlatformId && validation.errors.bannerPlatformId) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.bannerPlatformId}</div>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Group Page Name
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="pageName"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="pageName"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.pageName}
                            invalid={(validation.touched.pageName && validation.errors.pageName ? true : false ) || pageNameError}
                            disabled={pageView === "view"}
                          />
                          { 
                            pageNameError === true 
                            ? <div type="invalid" className="invalid-feedback" style={{ display: 'block' }}>Group page name already exists</div>
                            : (validation.touched.pageName && validation.errors.pageName)&& (
                              <FormFeedback type="invalid"> {validation.errors.pageName}</FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="description"
                            className="form-control"
                            type="textarea"
                            rows="3"
                            placeholder=""
                            name="description"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description}
                            invalid={ validation.touched.description && validation.errors.description ? true: false}
                            disabled={pageView === "view"}
                          />
                          { 
                            validation.touched.description && validation.errors.description && (
                              <FormFeedback type="invalid">
                                {validation.errors.description}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner Width
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="bannerWidth"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="bannerWidth"
                            onInput={(e)=> fn_helper.FNFORM.handleNumberOnly(e)}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.bannerWidth}
                            invalid={validation.touched.bannerWidth && validation.errors.bannerWidth ? true : false}
                            disabled={['update','view'].includes(pageView)}
                          />
                          { 
                            validation.touched.bannerWidth && validation.errors.bannerWidth && (
                              <FormFeedback type="invalid">
                                {validation.errors.bannerWidth}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner Height
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="bannerHeight"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="bannerHeight"
                            onInput={(e)=> fn_helper.FNFORM.handleNumberOnly(e)}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.bannerHeight}
                            invalid={validation.touched.bannerHeight && validation.errors.bannerHeight ? true : false}
                            disabled={['update','view'].includes(pageView)}
                          />
                          { 
                            validation.touched.bannerHeight && validation.errors.bannerHeight && (
                              <FormFeedback type="invalid">
                                {validation.errors.bannerHeight}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner Mobile Width
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="bannerMobileWidth"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="bannerMobileWidth"
                            onInput={(e)=> fn_helper.FNFORM.handleNumberOnly(e)}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.bannerMobileWidth}
                            invalid={validation.touched.bannerMobileWidth && validation.errors.bannerMobileWidth ? true : false}
                            disabled={['update','view'].includes(pageView)}
                          />
                          { 
                            validation.touched.bannerMobileWidth && validation.errors.bannerMobileWidth && (
                              <FormFeedback type="invalid">
                                {validation.errors.bannerMobileWidth}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-6 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Banner Mobile Height
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Input
                            id="bannerMobileHeight"
                            className="form-control"
                            type="text"
                            placeholder=""
                            name="bannerMobileHeight"
                            onInput={(e)=> fn_helper.FNFORM.handleNumberOnly(e)}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.bannerMobileHeight}
                            invalid={validation.touched.bannerMobileHeight && validation.errors.bannerMobileHeight ? true : false}
                            disabled={['update','view'].includes(pageView)}
                          />
                          { 
                            validation.touched.bannerMobileHeight && validation.errors.bannerMobileHeight && (
                              <FormFeedback type="invalid">
                                {validation.errors.bannerMobileHeight}
                              </FormFeedback>
                            )
                          }
                        </div>
                      </Col>
                      <Col className="col-12 mb-3">
                        <label
                          htmlFor="category_name"
                          className="col-md-12 col-form-label"
                        >
                          Status (Active/Inactive)
                          <span className="t-require">*</span>
                        </label>
                        <div className="col-md-12">
                          <Select
                            placeholder="Select Status"
                            value={fn_helper.FNFORM.getObjectValue(STATUS_OPTION, `${validation.values.status}`)}
                            onChange={(value) => validation.setFieldValue("status", value.id)}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            options={STATUS_OPTION}
                            className={`select2-selection ${validation.touched.status && validation.errors.status ? "input-err" : ""}` }
                            isDisabled={pageView === "view"}
                          />
                          {
                            (validation.touched.status && validation.errors.status) && (
                              <div type="invalid" className="invalid-feedback d-block">{validation.errors.status}</div>
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '8px'}}>
                      {
                        pageView == "view"
                        ? <><button className="btn btn-primary w-md" onClick={() => navigate('/banner/banner-page')}>BACK</button></> 
                        : <>
                          <button type="submit" className="btn btn-primary w-md" >SAVE</button>
                          <button className="btn btn-secondary w-md" onClick={() => navigate('/banner/banner-page')}>CANCEL</button>
                        </>
                      }
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

// QuestionaireCategory.propTypes = {
//     t: PropTypes.any,
// };

export default withTranslation()(BannerPageTemplate)
