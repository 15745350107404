import React from "react";
import { Navigate } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// TS Route
import UserList from "../pages/Systemsettings/UserList"
import UserAdd  from "../pages/Systemsettings/UserAdd"
import UserEdit from "../pages/Systemsettings/UserEdit"

// Questionaire Page
import QuestionaireCategoryList from "pages/Questionaire/CategoryList";
import QuestionaireCategoryCreate from "pages/Questionaire/QuestionaireCategory";
import QuestionaireCategoryEdit from "pages/Questionaire/QuestionaireCategoryEdit";

import QuestionaireList from "pages/Questionaire/QuestionaireList";
import QuestionaireCreate from "pages/Questionaire/QuestionaireCreate";
import QuestionaireEdit from "pages/Questionaire/QuestionaireEdit";


// Frontweb
import QuestionnaireFrontweb from "pages/Frontweb/QuestionnaireFrontweb";
import BannerPlatformList from "pages/BannerPlatform/BannerPlatformList";
import BannerPlatformTemplate from "pages/BannerPlatform/BannerPlatformTemplate";
import BannerPageList from "pages/BannerPage/BannerPageList";
import BannerPageTemplate from "pages/BannerPage/BannerPageTemplate";
import BannerList from "pages/BannerList/BannerList";
import BannerListTemplate from "pages/BannerList/BannerListTemplate";

// Survey page
import SurveyListPage from "pages/Survey/List";
import SurveyFormTemplatePage from "pages/Survey/FormTemplate";

// Customer page
import CustomerListPage from "pages/Customer/List";
import CustomerFormTemplatePage from "pages/Customer/FormTemplate";

// Point page
import PointTypeListPage from "pages/Point/Type/List";
import PointTypeFormTemplatePage from "pages/Point/Type/FormTemplate";
import PointConfigListPage from "pages/Point/Config/List";
import PointConfigFormTemplatePage from "pages/Point/Config/FormTemplate";
import PointSpecialEventConfigListPage from "pages/Point/SpecialEventConfig/List";
import PointSpecialEventConfigFormTemplatePage from "pages/Point/SpecialEventConfig/FormTemplate";
import BrandList from "pages/Brand/BrandList";
import BrandTemplate from "pages/Brand/BrandTemplate";
import EMagazineList from "pages/EMagazine/EMagazineList";
import EMagazineTemplate from "pages/EMagazine/EMagazineTemplate";
import ArticleCategoryList from "pages/ArticleCategory/ArticleCategoryList";
import ArticleCategoryTemplate from "pages/ArticleCategory/ArticleCategoryTemplate";
import ArticleKeywordList from "pages/ArticleKeyword/ArticleKeywordList";
import ArticleKeywordTemplate from "pages/ArticleKeyword/ArticleKeywordTemplate";
import ArticleList from "pages/Article/ArticleList";
import ArticleTemplate from "pages/Article/ArticleTemplate";
import TemplateList from "pages/Template/TemplateList";
import TemplateTemplate from "pages/Template/TemplateTemplate";
import BannerMainList from "pages/BannerMain/BannerMainList";
import BannerMainTemplate from "pages/BannerMain/BannerMainTemplate";
import BannerImageProductList from "pages/BannerImageProduct/BannerImageProductList";
import BannerImageProductTemplate from "pages/BannerImageProduct/BannerImageProductTemplate";
import BankList from "pages/Bank/BankList";
import BankTemplate from "pages/Bank/BankTemplate";
import PromotionCategoryList from "pages/PromotionCategory/PromotionCategoryList";
import PromotionCategoryTemplate from "pages/PromotionCategory/PromotionCategoryTemplate";
import JobTypeList from "pages/JobsManagment/JobType/JobTypeList";
import JobTypeTemplate from "pages/JobsManagment/JobType/JobTypeTemplate";
import JobPositionList from "pages/JobsManagment/JobPosition/JobPositionList";
import JobPositionTemplate from "pages/JobsManagment/JobPosition/JobPositionTemplate";
import JobApplicationList from "pages/JobsManagment/JobApplication/JobApplicationList";
import JobApplicationTemplate from "pages/JobsManagment/JobApplication/JobApplicationTemplate";
import JobApplicationPrint from "pages/JobsManagment/JobApplication/JobApplicationPrint";
import PrintComponent from "pages/JobsManagment/JobApplication/PrintComponent";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/systemsettings/usersetting", component: <UserList /> },
  { path: "/systemsettings/usersetting/useradd",  component: <UserAdd /> },
  { path: "/systemsettings/usersetting/useredit/:userId", component: <UserEdit /> },

  // for AI Shopping
  { path: "/questionnaire", component: <QuestionaireCategoryList /> },
  { path: "/questionnaire/category", component: <QuestionaireCategoryList /> },
  { path: "/questionnaire/category/create", component: <QuestionaireCategoryCreate /> },
  { path: "/questionnaire/category/edit/:categoryId", component: <QuestionaireCategoryEdit /> },

  { path: "/questionnaire/list/:categoryName", component: <QuestionaireList /> },
  // { path: "/questionnaire/create/:categoryId", component: <QuestionaireCreate /> },
  { path: "/questionnaire/create", component: <QuestionaireCreate /> },
  // { path: "/questionnaire/edit/:oldCategoryId/:newCategoryId", component: <QuestionaireEdit /> },
  { path: "/questionnaire/edit/:categoryId", component: <QuestionaireEdit /> },



  /* Banner route */
  { path: "/banner/banner-platform", component: <BannerPlatformList /> },
  { path: "/banner/banner-platform/create", component: <BannerPlatformTemplate pageView="create" /> },
  { path: "/banner/banner-platform/edit/:id", component: <BannerPlatformTemplate pageView="update" /> },
  { path: "/banner/banner-platform/view/:id", component: <BannerPlatformTemplate pageView="view" /> },

  { path: "/banner/banner-page", component: <BannerPageList /> },
  { path: "/banner/banner-page/create", component: <BannerPageTemplate pageView="create" /> },
  { path: "/banner/banner-page/edit/:id", component: <BannerPageTemplate pageView="update" /> },
  { path: "/banner/banner-page/view/:id", component: <BannerPageTemplate pageView="view" /> },

  { path: "/banner/banner-list", component: <BannerList /> },
  { path: "/banner/banner-list/create", component: <BannerListTemplate pageView="create" /> },
  { path: "/banner/banner-list/edit/:id", component: <BannerListTemplate pageView="update" /> },
  { path: "/banner/banner-list/view/:id", component: <BannerListTemplate pageView="view" /> },

  /* Brand route */
  { path: "/brand", component: <BrandList /> },
  { path: "/brand/create", component: <BrandTemplate pageView="create" /> },
  { path: "/brand/edit/:id", component: <BrandTemplate pageView="update" /> },
  { path: "/brand/view/:id", component: <BrandTemplate pageView="view" /> },
  /* End Brand route */

  /* E-Magazine route */
  { path: "/e-magazine", component: <EMagazineList /> },
  { path: "/e-magazine/create", component: <EMagazineTemplate pageView="create" /> },
  { path: "/e-magazine/edit/:id", component: <EMagazineTemplate pageView="update" /> },
  { path: "/e-magazine/view/:id", component: <EMagazineTemplate pageView="view" /> },
  /* End E-Magazine route */

  /* Article route */
  { path: "/article/category", component: <ArticleCategoryList /> },
  { path: "/article/category/create", component: <ArticleCategoryTemplate pageView="create" /> },
  { path: "/article/category/edit/:id", component: <ArticleCategoryTemplate pageView="update" /> },
  { path: "/article/category/view/:id", component: <ArticleCategoryTemplate pageView="view" /> },

  { path: "/article/keyword", component: <ArticleKeywordList /> },
  { path: "/article/keyword/create", component: <ArticleKeywordTemplate pageView="create" /> },
  { path: "/article/keyword/edit/:id", component: <ArticleKeywordTemplate pageView="update" /> },
  { path: "/article/keyword/view/:id", component: <ArticleKeywordTemplate pageView="view" /> },

  { path: "/article/article", component: <ArticleList /> },
  { path: "/article/article/create", component: <ArticleTemplate pageView="create" /> },
  { path: "/article/article/edit/:id", component: <ArticleTemplate pageView="update" /> },
  { path: "/article/article/view/:id", component: <ArticleTemplate pageView="view" /> },

  /* End Brand route */


  /* Banner Supplier route */
    { path: "/banner-supplier/template", component: <TemplateList /> },
    { path: "/banner-supplier/template/create", component: <TemplateTemplate pageView="create" /> },
    { path: "/banner-supplier/template/edit/:id", component: <TemplateTemplate pageView="update" /> },
    { path: "/banner-supplier/template/view/:id", component: <TemplateTemplate pageView="view" /> },

    { path: "/banner-supplier/banner-main", component: <BannerMainList /> },
    { path: "/banner-supplier/banner-main/create", component: <BannerMainTemplate pageView="create" /> },
    { path: "/banner-supplier/banner-main/edit/:id", component: <BannerMainTemplate pageView="update" /> },
    { path: "/banner-supplier/banner-main/view/:id", component: <BannerMainTemplate pageView="view" /> },

    { path: "/banner-supplier/banner-image-product", component: <BannerImageProductList /> },
    { path: "/banner-supplier/banner-image-product/create", component: <BannerImageProductTemplate pageView="create" /> },
    { path: "/banner-supplier/banner-image-product/edit/:id", component: <BannerImageProductTemplate pageView="update" /> },
    { path: "/banner-supplier/banner-image-product/view/:id", component: <BannerImageProductTemplate pageView="view" /> },

  /* End Banner Supplier route */

  /* Banner Supplier route */
  { path: "/jobs-managment/job-type", component: <JobTypeList /> },
  { path: "/jobs-managment/job-type/create", component: <JobTypeTemplate pageView="create" /> },
  { path: "/jobs-managment/job-type/edit/:id", component: <JobTypeTemplate pageView="update" /> },
  { path: "/jobs-managment/job-type/view/:id", component: <JobTypeTemplate pageView="view" /> },

  { path: "/jobs-managment/job-position", component: <JobPositionList /> },
  { path: "/jobs-managment/job-position/create", component: <JobPositionTemplate pageView="create" /> },
  { path: "/jobs-managment/job-position/edit/:id", component: <JobPositionTemplate pageView="update" /> },
  { path: "/jobs-managment/job-position/view/:id", component: <JobPositionTemplate pageView="view" /> },

  { path: "/jobs-managment/job-application", component: <JobApplicationList /> },
  { path: "/jobs-managment/job-application/edit/:id", component: <JobApplicationTemplate pageView="update"/> },
  { path: "/jobs-managment/job-application/print/:id", component: <JobApplicationPrint/> },
  /* End Banner Supplier route */

  /* Bank route */
  { path: "/bank", component: <BankList /> },
  { path: "/bank/create", component: <BankTemplate pageView="create"/> },
  { path: "/bank/edit/:id", component: <BankTemplate pageView="update" /> },
  { path: "/bank/view/:id", component: <BankTemplate pageView="update" /> },
  /* Bank END route */

  /* Article route */
  { path: "/promotion/category", component: <PromotionCategoryList /> },
  { path: "/promotion/category/create", component: <PromotionCategoryTemplate pageView="create" /> },
  { path: "/promotion/category/edit/:id", component: <PromotionCategoryTemplate pageView="update" /> },
  { path: "/promotion/category/view/:id", component: <PromotionCategoryTemplate pageView="view" /> },

  // { path: "/banner/banner-platform/edit/:id", component: <BannerPlatformTemplate pageView="update" /> },
  // { path: "/banner/banner-platform/view/:id", component: <BannerPlatformTemplate pageView="view" /> },

  /* Survey route */
  { path: "/survey", component: <SurveyListPage /> },
  { path: "/survey/create", component: <SurveyFormTemplatePage pageView="create" /> },
  { path: "/survey/edit/:id", component: <SurveyFormTemplatePage pageView="update" /> },
  /* End Survey route */

  /* Customer route */
  { path: "/customer", component: <CustomerListPage /> },
  { path: "/customer/edit/:id", component: <CustomerFormTemplatePage pageView="update" /> },
  { path: "/customer/view/:id", component: <CustomerFormTemplatePage pageView="view" /> },
  /* End Customer route */

  /* Point route */
  { path: "/point/type", component: <PointTypeListPage /> },
  { path: "/point/type/create", component: <PointTypeFormTemplatePage pageView="create" /> },
  { path: "/point/type/edit/:id", component: <PointTypeFormTemplatePage pageView="update" /> },
  { path: "/point/config", component: <PointConfigListPage /> },
  { path: "/point/config/create", component: <PointConfigFormTemplatePage pageView="create" /> },
  { path: "/point/config/edit/:id", component: <PointConfigFormTemplatePage pageView="update" /> },
  { path: "/point/special-event-config", component: <PointSpecialEventConfigListPage /> },
  { path: "/point/special-event-config/create", component: <PointSpecialEventConfigFormTemplatePage pageView="create" /> },
  { path: "/point/special-event-config/edit/:id", component: <PointSpecialEventConfigFormTemplatePage pageView="update" /> },
  /* End Point route */

  /* Article route */


  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-maintenance", component: <PagesMaintenance /> },
  { path: "/pages-comingsoon", component: <PagesComingsoon /> },
  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },
  { path: "/print", component: <PrintComponent /> },

  {path: "/make/questionnaire/:questionnaireUUID", component: <QuestionnaireFrontweb />}
];

export { authProtectedRoutes, publicRoutes };
