import { get, post, put } from "../Config"

export function DropdownBannerPlatform({ params = {} }) {
  return new Promise((resolve, reject) => {
    resolve(get({ url: `/v1/admin/dropdown/bannerPlatform`, params }))
  })
}
export function DropdownBannerPage({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/bannerPage`, params })
}
export function DropdownArticleCategory({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/articleCategory`, params })
}
export function DropdownArticleKeyword({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/articleKeyword`, params })
}
export function DropdownBrand({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/brand`, params })
}
export function DropdownTemplate({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/template`, params })
}
export function DropdownProductGroup({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/productGroup`, params })
}
export function DropdownBannerMain({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/bannerSupplierMain`, params })
}
export function DropdownPromotionCategory({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/promotionCategory`, params })
}
export function DropdownJobType({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/jobType`, params })
}
export function DropdownJobLocation({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/jobLocation`, params })
}
export function DropdownJobPosition({ params = {} }) {
  return get({ url: `/v1/admin/dropdown/jobPosition`, params })
}